import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../components/layout'
import Hero from '../../components/heroSubpage'
import SEO from '../../components/seo'
import AltSection from '../../components/columnAlternating'

const Services = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback.src}       

        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }} />
                <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} /> </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              {post.frontmatter.section.map((services, index) => {
                return (
                  <div key={index}>
                    <AltSection
                      key={index}
                      title={services.title}
                      subtitle={services.subtitle}
                      description={services.description}
                      image={services.image.childImageSharp.gatsbyImageData}
                      imageAltText="An example of a UniGW dashboard, used to digitally transform enterprise applications"
                      placement={services.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pt-3 title-xs-medium title-medium text-very-dark-blue"
                      link=""
                      anchor={services.link}
                      colour="ficon-digital"
                    />
                  </div>
                )
              })}
            </MDBContainer>
          </section>
        )}
      </Layout>
    </div>
  )
}
export default Services

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      frontmatter: { template: { eq: "other" }, title: { eq: "Services" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        alttext
          image {
            childImageSharp {
              gatsbyImageData(width: 1980, quality: 90) 
            }
          }
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 90) 
            }
          }
          alttext
          placement
          description
          linktext
          link
        }
      }
      html
    }
  }
`